<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('payment_approval_exceptions')"
		                @filter-div-status="datatable.filterStatus = $event">
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('payment_approval_exceptions')"
		                      @filter-div-status="datatable.filterStatus = $event">
		        </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.number">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.national_id">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('date_between')" class="mb-0">
                            <v-date-picker v-model="datatable.queryParams.filter.date_between" is-range>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import PeriodService from "@/services/PeriodService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentApprovalExceptionService from "@/services/PaymentApprovalExceptionService";

export default {
    components: {
       AppLayout,
	    Header,
	    HeaderMobile,
        DatatableFilter,
        Datatable,
    },
    metaInfo() {
        return {
            title: this.$t("payment_approval_exceptions"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [

                    {
                        label: this.toUpperCase("student_number"),
                        field: 'number',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("national_id"),
                        field: 'national_id',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("payment_status"),
                        field: 'status',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("explanation"),
                        field: 'explanation',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("expiry_date"),
                        field: 'expiry_date',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("created_by"),
                        field: 'created_by',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("created_at"),
                        field: 'created_at',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let params = {
                ...this.datatable.queryParams,
            };

            const config = {
                params: params,
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return PaymentApprovalExceptionService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.year_code) {
                    this.$refs.storeForm.errors.year_code.push(error.data.errors.year_code[0]);
                }

                if (error.data.errors.semester) {
                    this.$refs.storeForm.errors.semester.push(error.data.errors.semester[0]);
                }

            } else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
    },
};
</script>

